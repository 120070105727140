/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };



  function configurer(){
    
      if($(window).width() < 1024) {
        $('.language-selector-mobile p.current').click(function() {
          $(this).parent().find('ul').slideToggle();
        });
      }

      $('#bt-menu').on('click', function() {
        $('body').toggleClass('menu-open');
      });

      $('#dist-selector').change(function() {
        var id_list = $('#dist-selector').find(":selected").attr('data-id');
        var array_id = id_list.split(',');
        
        if(array_id == '-1'){
          $('.term-list-wrapper').each(function() {
              $(this).fadeIn();
          });
        }else {

          hideDealerList();
          

          setTimeout(function() {
            hideTermList();

            jQuery.each(array_id, function(index, item) {
              var arr_length = array_id.length;

              refreshDealerList(index, item, arr_length-1);
            });
          }, 750); 

        }      

      });
  }

  function hideDealerList() {
    $('.distributeurs-list').fadeOut();
  }

  function showDealerList() {
    $('.distributeurs-list').fadeIn();
  }

  function hideTermList() {
    $('.term-list-wrapper').each(function() {
      $(this).fadeOut();
    });
  }

  function refreshDealerList(index, item, arr_length) {
    $('.term-list-wrapper').each(function() {
      var data_id = $(this).attr('data-id');
      if(item == data_id){
        $(this).fadeIn();
      }
    });


    if(index == arr_length){
      setTimeout(function(){
         showDealerList();
      }, 750);      
    }
  }

  //------- VIDEO LIGHTBOX -------
        // Vidéo trigger
        $('.videoTrigger').click(function() {
                           //Si c'est un vidéo
                $('#video-lightbox').removeClass('hidden-lightbox');
                setTimeout(function() {
                    $('#video-lightbox').addClass('open');
                }, 500);


                var idVideo = $(this).attr('data-video-id');

                $('#video-lightbox .video[data-video-id="'+idVideo+'"]').addClass('visible');
                $('body').addClass('no-scroll');
        });


        //Close video lightbox, on click
        $('#video-lightbox').click(function() {
            if ($(this).hasClass('open')) {
                $('#video-lightbox').removeClass('open');
                setTimeout(function() {
                    $('body').removeClass('no-scroll');
                    $('#video-lightbox').addClass('hidden-lightbox');
                }, 600);
            }

            $('#video-lightbox .video.visible').removeClass('visible');
        });
        //Close video lightbox, on click on close-button
        $('button.close-videoPanel').click(function() {
            $('#video-lightbox').removeClass('open');
            setTimeout(function() {
                $('body').removeClass('no-scroll');
                $('#video-lightbox').addClass('hidden-lightbox');
            }, 600);

            $('#video-lightbox .video.visible').removeClass('visible');

        });

  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
